<style>
.input-readonly>div>.vs-inputx {
    background-color: #e6e6e6;
}
</style> 
<template>
    <vx-card title="AR Write Off">
        <div class="vx-row mb-12">

            <div class="vx-col w-full ">
                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <vs-button type="line" icon-pack="feather" color="Red" icon="icon-arrow-left" v-on:click.stop="
                            handleBack()" />
                    </div>
                    <div class="vx-col w-1/2">

                    </div>
                </div>
                <!-- UI Changes -->
                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Aging (More Than)</label>
                        <vs-input class="w-full" v-model="create.aging" />
                    </div>
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Salesman</label>
                        <multiselect class="selectExample" v-model="create.salesman" :options="option.salesman"
                            :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                            :limit="4" :loading="loading.salesman" placeholder=" Type to search" track-by="ID"
                            label="Name" @search-change="getOptionSalesman">
                        </multiselect>
                    </div>
                </div>
                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Value (Less Than)</label>
                        <vs-input class="w-full" v-model="create.value"
                            @keyup="create.value = formatPrice(create.value.toString())" />
                    </div>
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Distribution Channel</label>
                        <multiselect class="selectExample" v-model="create.distChannel" :options="option.distChannel"
                            :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                            :limit="4" :loading="loading.distChannel" placeholder=" Type to search" track-by="ID"
                            label="name">                            
                        </multiselect>
                    </div>
                </div>
                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Operating Unit</label>
                        <multiselect class="selectExample" v-model="create.operatingUnit" :options="option.operatingUnit"
                            track-by="operating_unit_id" label="operating_unit_name" :multiple="false" :allow-empty="false"
                            :group-select="false" :max-height="160" :limit="4" placeholder=" Type to search"
                            @input="operatingUnitSelected">
                            <template slot="singleLabel" slot-scope="dt">
                                <span class="option__desc">
                                    <span class="option__title">{{
                                        dt.option.operating_unit_code + ' ' +
                                        dt.option.operating_unit_name
                                    }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="dt">
                                <div class="option__desc">
                                    <span class="option__title">{{
                                        dt.option.operating_unit_code + ' ' +
                                        dt.option.operating_unit_name
                                    }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Customer</label>
                        <multiselect class="selectExample" v-model="create.customer" :options="option.customer"
                            :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                            :limit="4" placeholder=" Type to search" track-by="ID" label="code_name"
                            :loading="loading.customer" @search-change="getOptionCustomer">
                        </multiselect>
                    </div>    
                </div>

                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Territory</label>
                        <multiselect class="selectExample" v-model="create.territory" :options="option.territory"
                            track-by="territory_id" label="territory_name" :multiple="false" :allow-empty="false"
                            :group-select="false" :max-height="160" :limit="4" placeholder=" Type to search">
                            <template slot="singleLabel" slot-scope="dt">
                                <span class="option__desc">
                                    <span class="option__title">{{
                                        dt.option.territory_code + ' ' +
                                        dt.option.territory_name
                                    }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="dt">
                                <div class="option__desc">
                                    <span class="option__title">{{
                                        dt.option.territory_code + ' ' +
                                        dt.option.territory_name
                                    }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="vx-col w-3/12">
                        <label class="vs-input--label">Operation</label>
                        <multiselect class="selectExample" v-model="create.operation"
                            :options="option.operation" :multiple="false" :group-select="false"
                            :max-height="160" :limit="4" label="name" track-by="name"
                            placeholder=" Type to search">
                        </multiselect>
                    </div>
                    <div class="vx-col w-3/12">
                        <div class="vx-row w-full">
                            <div class="vx-col w-1/2">
                                <label class="vs-input--label"></label>
                                <vs-button type="line" icon-pack="feather" color="Green" icon="icon-search"
                                    v-on:click.stop="getDocument(tr)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="vx-row mb-2">
                        <div class="vx-col w-6/12">
                            <div class="vx-row mb-2 ml-1 mr-1">
                                <label class="vs-input--label">Transaction Type</label>
                                <multiselect class="selectExample" v-model="create.transactionType"
                                :options="option.transactionType" :multiple="false" :group-select="false" label="name"
                                @input="transactionTypeSelected" track-by="name" placeholder=" Type to search">
                                </multiselect>
                            </div>
                            <div class="vx-row mb-2 ml-1 mr-1">
                                <label class="vs-input--label">Note / Reason</label>
                                <vs-textarea class="w-full" label="Note" v-model="create.note" />
                            </div>
                        </div>
                        <div class="vx-col w-1/2">
                            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                                <div class="vx-col sm:w-1/3 w-full">
                                    <span>File</span>
                                </div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <div class="vx-col sm:w-4/5 w-full">
                                        <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                            multiple="multiple" accept=".jpg, .jpeg, .png, .pdf" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <div class="vx-col sm:w-4/5 w-full">
                                        <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
                                    </div>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                                <vs-divider>List Attachment</vs-divider>
                                <table class="vs-table vs-table--tbody-table">
                                    <template v-for="(tr, i) in fileAttachment">
                                        <tr class="tr-values vs-table--tr tr-table-state-null selected"
                                            v-bind:key="tr.NameFile">
                                            <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                            <td class="td vs-table--td">
                                                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                                                    <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                                        @click.stop="handleShowAttachment(tr)" />
                                                </vx-tooltip>
                                            </td>
                                            <td class="td vs-table--td">
                                                <template>
                                                    <vx-tooltip text="Delete">
                                                        <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                                            @click.stop="handleDeleteAttachment(i)" />
                                                    </vx-tooltip>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div>
                <div>
                    <vs-prompt color="primary" title="Confirmation" @cancel="activePromptConfrm = false"
                        @accept="doGenerate" @close="closeConfirm" :active.sync="activePromptConfrm">
                        <div class="con-exemple-prompt">
                            Are you sure generate ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                </div>
            </div>

            <div v-bind:class="[
                checked.length > 0 ? detailShow + ' vertical-divider' : '',
                detailHide,
            ]">
                <data-table :responseData="responseData" :propsParams="params" :header="header"
                    @reloadDataFromChild="reloadData">
                    <template slot="thead">
                        <th width="7%">
                            -
                        </th>
                    </template>
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td class="whitespace-no-wrap">
                                <vs-checkbox color="success"
                                    :checked="checkedID.includes(paramDetail.type_doc + '-' + tr.ID)"
                                    v-on:click="addChecked(paramDetail.type_doc + '-' + tr.ID, tr.ID)"></vs-checkbox>
                        </vs-td>
                        <template v-if="paramDetail.type_doc == '01'">
                                <vs-td>
                                    {{ tr.UserName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.ClientCode }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.ClientName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Code }}
                                </vs-td>
                                <vs-td>
                                    {{ globalDateFormat(tr.Date) }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Aging }} Days
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.Remaining)) }}
                                </vs-td>                                
                            </template>
                            <template v-if="paramDetail.type_doc == '02'">
                                <vs-td>
                                    {{ tr.UserName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.PersonCode }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.PersonName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Code }}
                                </vs-td>
                                <vs-td>
                                    {{ globalDateFormat(tr.DatePayment) }}
                                    <!-- {{ globalDateFormat(tr.Date) }} -->
                                </vs-td>
                                <vs-td>
                                    {{ tr.Aging }} Days
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.DepositValueRaw)) }}
                                </vs-td>

                        </template>
                        <template v-if="paramDetail.type_doc == '03'">
                                <vs-td>
                                    {{ tr.UserName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.CustomerCode }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.CustomerName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Code }}
                                </vs-td>
                                <vs-td>
                                    {{ globalDateFormat(tr.Date) }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Aging }} Days
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.TotalValue - tr.UsedValue)) }}
                                </vs-td>

                            </template>

                        </vs-tr>
                    </template>
                </data-table>
                <!-- Approve Prompt -->

            </div>
            <!-- form -->
            <transition name="detail-fade">
                <div v-if="checked.length > 0" v-bind:class="[
                    checked.length > 0 ? detailShow + 'vx-col md:w-1/2 w-full  mb-base' : '',
                    detailHide,
                ]">
                    <vs-row ml-2><b>Selected</b></vs-row>
                    <vs-row>

                        <vs-table stripe max-items="10" pagination :data="checked">

                            <template slot="thead">
                                <vs-th>Action</vs-th>
                                <vs-th>Salesman</vs-th>
                                <vs-th>Customer Code</vs-th>
                                <vs-th>Customer Name</vs-th>
                                <vs-th>Code</vs-th>
                                <vs-th>Date</vs-th>
                                <vs-th>Aging</vs-th>
                                <vs-th>Assignment</vs-th>
                                <vs-th>Value</vs-th>
                                <vs-th>% Provision</vs-th>
                                <vs-th>% Expense</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td>
                                        <vs-button type="line" icon-pack="feather" color="Red" icon="icon-trash"
                                            v-on:click.stop="
                                                addChecked(paramDetail.type_doc + '-' + tr.ID, tr.ID, 'del')" />
                                </vs-td>
                                <template v-if="paramDetail.type_doc == '01'">
                                        <vs-td>
                                            {{ tr.UserName }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.ClientCode }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.ClientName }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Code }}
                                        </vs-td>
                                        <vs-td>
                                            {{ globalDateFormat(tr.Date) }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Aging }} Days
                                        </vs-td>
                                        <vs-td>
                                            <!-- {{ "ASSIGNMENT" }} -->
                                            <vs-input class="w-full" v-model="checkedAssignment[indextr]"/>
                                        </vs-td>
                                        <vs-td>
                                            {{ priceFormat(parseFloat(tr.Remaining)) }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Provision != NULL ? priceFormat(parseFloat(tr.Provision)) : "-" }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Expense != NULL ? priceFormat(parseFloat(tr.Expense)) : "-" }}
                                        </vs-td>

                                    </template>
                                    <template v-if="paramDetail.type_doc == '02'">
                                        <vs-td>
                                            {{ tr.UserName }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.PersonCode }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.PersonName }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Code }}
                                        </vs-td>
                                        <vs-td>
                                            {{ globalDateFormat(tr.DatePayment) }}
                                            <!-- {{ globalDateFormat(tr.Date) }} -->
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Aging }} Days
                                        </vs-td>
                                        <vs-td>
                                            <!-- {{ "ASSIGNMENT" }} -->
                                            <vs-input class="w-full" v-model="checkedAssignment[indextr]"/>
                                        </vs-td>
                                        <vs-td>
                                            {{ priceFormat(parseFloat(tr.DepositValueRaw)) }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Provision != NULL ? priceFormat(parseFloat(tr.Provision)) : "-" }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Expense != NULL ? priceFormat(parseFloat(tr.Expense)) : "-" }}
                                        </vs-td>
                                </template>
                                <template v-if="paramDetail.type_doc == '03'">
                                        <vs-td>
                                            {{ tr.UserName }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.CustomerCode }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.CustomerName }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Code }}
                                        </vs-td>
                                        <vs-td>
                                            {{ globalDateFormat(tr.Date) }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Aging }} Days
                                        </vs-td>
                                        <vs-td>
                                            <!-- {{ "ASSIGNMENT" }} -->
                                            <vs-input class="w-full" v-model="checkedAssignment[indextr]"/>
                                        </vs-td>
                                        <vs-td>
                                            {{ priceFormat(parseFloat(tr.TotalValue - tr.UsedValue)) }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Provision != NULL ? priceFormat(parseFloat(tr.Provision)) : "-" }}
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.Expense != NULL ? priceFormat(parseFloat(tr.Expense)) : "-" }}
                                        </vs-td>

                                    </template>


                                </vs-tr>
                            </template>
                        </vs-table>
                    </vs-row>
                    <vs-row>
                        <vs-button color="primary" type="filled" v-on:click="generateWriteOff()">Generate</vs-button>
                    </vs-row>
                </div>
            </transition>
        </div>
    </vx-card>
</template>
<script>
import moment from "moment"

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
    components: {

    },
    props: {
        selected: Object,
    },
    data() {
        return {
            option: {
                transactionType: [
                    { name: 'AR INV', value: '01' },
                    { name: 'Cust Deposit', value: '02' },
                    { name: 'Credit Note', value: '03' },
                ],
                type: [
                    { name: 'Allowance', value: '0' },
                    { name: 'Expense', value: '1' },
                ],
                operation: [
                    { name: 'Or', value: 0 },
                    { name: 'And', value: 1 },
                ],
                territory: [],
                operatingUnit: [],
                salesman: [],
                customer: [],
                distChannel: [],
            },
            loading: {
                salesman: false,
                distChannel: false,
            },
            counterLoading: {
                salesman: 0,
                customer: 0,
                distChannel: 0,
            },
            params: {
                search: "",
                length: 10,
                page: 1,
                order: "desc",
                sort: "id",
            },
            optionMethod: [],
            activePromptConfrm: false,
            header: [
                {
                    text: "Salesman",
                    value: "salesman",
                },
                {
                    text: "Customer Code",
                    value: "customer_code",
                    // width: '5%'
                },
                {
                    text: "Customer Name",
                    value: "customer_name",
                },
                {
                    text: "Code",
                    value: "code",
                },
                {
                    text: "Date",
                    value: "date",
                },
                {
                    text: "Aging",
                    sortable: false,
                },
                {
                    text: "Value",
                    sortable: false,
                }
            ],
            data: [],
            checked: [],
            checkedID: [],
            checkedAssignment: [],
            paramDetail: {},
            optionOU: [],
            operatingUnit: {},
            methods: ['', 'Cash', 'Giro', 'Cheque', 'Transfer'],
            responseData: {},
            create: {
                transactionType: null,
                type: null,
                method: "",
                operatingUnit: null,
                salesman: null,
                territory: null,
                distChannel: null,
                customer: null,
                value: 0,
                aging: 0,
                operation: null,
                // assignment: null
            },
            detailShow: "vx-col md:w-1/2 w-full mb-base",
            detailHide: "core vx-col md:w-1/1 w-full mb-base",
            detail: false,
            notes: "",
            bankStatement: {},
            approveOrReject: false,
            approvalPrompt: false,
            rejectPrompt: false,
            selectedData: {},
            bankStatementID: null,
            fileAttachment: [],
            settingWriteOff: []
        };
    },
    computed: {

        // operatingUnit(){
        //   console.log(this.operatingUnit,"ou")
        // }
    },
    watch: {
        operatingUnit() {
            // console.log(this.operatingUnit, "ou")
            this.reloadData(this.params)
            this.handleClose()
        },
    },
    mounted() {
        // console.log(this.$route.params.id)
        // this.bankStatementID = this.$route.params.id
        // // this.reloadData(this.$route.params.id);

        this.getOptionOU()
        this.getOptionDistChannel()
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        getData() {

            this.$http.get('/api/v1/write-off/' + this.$route.params.id).then((r) => {
                if (r.code == 200) {
                    // console.log(r)
                    const records = r.data.records
                    const recordLines = r.data.lines
                    if (records.Status != 0) {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: records.Code + " cannot edit",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$router.push("/finance/write-off");
                        return
                    }
                    const type = this.option.type.filter(v => {
                        // console.log(v, records)
                        return v.value == records.Type
                    })

                    this.create.type = type[0]
                    this.create.note = records.Note
                    // this.create.assignment = records.Assignment
                    this.create.code = records.Code
                    this.fileAttachment = records.Attachment
                    const transactionType = this.option.transactionType.filter(v => {
                        return v.value == records.TransactionType
                    })
                    this.create.transactionType = transactionType[0]

                    const ouCode = this.option.operatingUnit.filter(v => {
                        return v.operating_unit_code == records.OperatingUnitCode 
                    })
                    const territoryCode = this.option.operatingUnit.filter(v => {
                        return v.territory_code == records.TerritoryCode
                    })
                    this.create.operatingUnit = ouCode[0]
                    this.create.territory = territoryCode[0]

                    let param = {
                        salesman: this.create.salesman == null ? 0 : this.create.salesman.ID,
                        type_doc: this.create.transactionType.value,
                        customer: this.create.customer == null ? 0 : this.create.customer.ID,
                    };
                    this.paramDetail = param
                    let checked = []
                    let checkedID = []
                    let checkedAssignment = []
                    // console.log('recordLines', recordLines)
                    recordLines.map(v => {
                        // console.log('vvv', v)
                        checkedID.push(param.type_doc + '-' + v.ReferenceID)
                        checkedAssignment.push(v.Assignment)
                        let array = {}
                        if (param.type_doc == "03") {
                            array = {
                                ID: v.ReferenceID,
                                Code: v.ReferenceCode,
                                CustomerName: v.PersonName,
                                CustomerCode: v.PersonCode,
                                CustomerID: v.PersonID,
                                Date: v.ReferenceDate,
                                Aging: v.Aging,
                                TotalValue: v.RemainingValue,
                                UsedValue: 0,
                                Provision: v.Provision,
                                Expense: v.Expense,
                                UserName: v.SalesmanName,
                                UserID: v.SalesmanID,
                            }
                        } else if (param.type_doc == "01") {
                            array = {
                                ID: v.ReferenceID,
                                Code: v.ReferenceCode,
                                ClientName: v.PersonName,
                                ClientCode: v.PersonCode,
                                ClientID: v.PersonID,
                                Date: v.ReferenceDate,
                                Aging: v.Aging,
                                Remaining: v.RemainingValue,
                                UsedValue: 0,
                                Provision: v.Provision,
                                Expense: v.Expense,
                                UserName: v.SalesmanName,
                                UserID: v.SalesmanID,
                            }
                        } else if (param.type_doc == "02") {
                            array = {
                                ID: v.ReferenceID,
                                Code: v.ReferenceCode,
                                PersonName: v.PersonName,
                                PersonCode: v.PersonCode,
                                PersonID: v.PersonID,
                                Date: v.ReferenceDate,
                                DatePayment: v.ReferenceDate,
                                Aging: v.Aging,
                                Remaining: v.RemainingValue,
                                DepositValueRaw: v.RemainingValue,
                                Provision: v.Provision,
                                Expense: v.Expense,
                                UserName: v.SalesmanName,
                                UserID: v.SalesmanID,
                            }
                        }
                        checked.push(array)
                    })
                    this.checked = checked
                    this.checkedID = checkedID
                    this.checkedAssignment = checkedAssignment

                    // console.log("checkedAssignment", this.checkedAssignment)
                    // this.create.aging = 1
                    // this.create.value = parseFloat('10000000000')
                    // this.create.operation = this.option.operation[0]
                    // this.getDocument()
                    // this.getOptionCustomer(recordLines[0].PersonName,recordLines[0].PersonID)
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: r.message,
                        text: "error catch",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.handleBack()
                }
            }).catch((e) => {
                console.log(e)
                this.$vs.notify({
                    color: "danger",
                    title: "error catch",
                    text: "error catch",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                // this.handleBack()
            })
        },
        addChecked(key, id, source = null) {
            if (this.settingWriteOff.length > 0 || source == 'del') {
                // console.log(this.checkedID.length);
                if (this.checkedID.includes(key)) {
                    this.checkedID.splice(this.checkedID.indexOf(key), 1);
                    this.checked = this.checked.filter(v => {
                        return v.ID != id
                    })
                } else {
                    this.checkedID.push(key);
                    let filter = this.data.filter(v => {
                        return v.ID == id
                    })
                    let ProvExpense = this.countProvisionExpense(filter[0]);
                    filter[0].Provision = ProvExpense.provision;
                    filter[0].Expense = ProvExpense.expense;
                    this.checked.push(filter[0])
                    this.checkedAssignment.push("")
                    // console.log(this.checked);
                    // console.log(this.checkedAssignment);
                }
            }else{
                this.$vs.notify({
                    color: "warning",
                    title: "Missing Setting",
                    text: "Please setup write-off parameter",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            }
        },

        transactionTypeSelected() {

        },
        handleBack() {
            this.$router.push("/finance/write-off");
        },
        getOptionOU() {
            this.$http.get("/api/v1/master/operating-unit-with-territory").then((resp) => {
                this.option.territoryAll = []
                this.option.territory = []
                this.option.operatingUnit = []
                let ou = []
                resp.data.records.map(r => {
                    let array = {
                        operating_unit_id: r.ID,
                        operating_unit_code: r.Code,
                        operating_unit_name: r.Name,
                        territory_id: r.TerritoryID,
                        territory_code: r.TerritoryCode,
                        territory_name: r.TerritoryName,
                    }
                    this.option.territoryAll.push(array)
                    if (!ou.includes(r.ID)) {
                        ou.push(r.ID)
                        this.option.operatingUnit.push(array)
                    }
                })
                this.immutable = false
                this.getData()
                // this.optionOU = resp.data.records;
                // this.operatingUnit = resp.data.records[0];
            });

        },
        dateFormat(value) {
            return moment.utc(String(value)).format("DD/MM/YYYY");
        },
        operatingUnitSelected() {
            this.create.customer = null
            this.create.salesman = null
            this.create.territory = null
            this.option.territory = this.option.territoryAll.filter(v => {
                return v.operating_unit_id == this.create.operatingUnit.operating_unit_id
            })
            this.checked = []
            this.checkedID = []
        },
        generateWriteOff() {
            this.activePromptConfrm = true
        },
        closeConfirm() {
            this.activePromptConfrm = false
        },
        doGenerate() {
            // if (this.create.type == null) {
            //     this.$vs.notify({
            //         color: "danger",
            //         title: "Required",
            //         text: "Write-off type is required",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-x-circle",
            //     });
            //     return
            // }
            this.paramDetail.operating_unit = this.create.operatingUnit.operating_unit_id
            this.paramDetail.operating_unit_code = this.create.operatingUnit.operating_unit_code
            this.paramDetail.territory_code = this.create.operatingUnit.territory_code
            this.paramDetail.note = this.create.note
            this.paramDetail.id = parseInt(this.$route.params.id)
            let detail = []
            if (this.create.transactionType.value == '03') {
                this.checked.map((v, i) => {
                    let array = {
                        aging: v.Aging,
                        code: v.Code,
                        id: v.ID,
                        customer_name: v.CustomerName,
                        customer_code: v.CustomerCode,
                        customer_id: v.CustomerID,
                        value: parseFloat(v.TotalValue - v.UsedValue),
                        date: v.Date,
                        provision: parseFloat(v.Provision),
                        expense: parseFloat(v.Expense),
                        assignment: this.checkedAssignment[i],
                        salesman_id: v.UserID,
                        salesman_name: v.UserName,
                    }
                    detail.push(array)
                    // console.log(v)
                })
            } else if (this.create.transactionType.value == '01') {
                this.checked.map((v, i) => {
                    let array = {
                        aging: v.Aging,
                        code: v.Code,
                        id: v.ID,
                        customer_name: v.ClientName,
                        customer_code: v.ClientCode,
                        customer_id: v.ClientID,
                        value: parseFloat(v.Remaining),
                        date: v.Date,
                        provision: parseFloat(v.Provision),
                        expense: parseFloat(v.Expense),
                        assignment: this.checkedAssignment[i],
                        salesman_id: v.UserID,
                        salesman_name: v.UserName,
                    }
                    detail.push(array)
                    // console.log(v)
                })
            } else if (this.create.transactionType.value == '02') {
                this.checked.map((v, i) => {
                    let array = {
                        aging: v.Aging,
                        code: v.Code,
                        id: v.ID,
                        customer_name: v.PersonName,
                        customer_code: v.PersonCode,
                        customer_id: v.PersonID,
                        value: parseFloat(v.DepositValueRaw),
                        date: v.DatePayment,
                        provision: parseFloat(v.Provision),
                        expense: parseFloat(v.Expense),
                        assignment: this.checkedAssignment[i],
                        salesman_id: v.UserID,
                        salesman_name: v.UserName,
                    }
                    detail.push(array)
                    // console.log(v)
                })
            }
            if (detail.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Noting to generate",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            this.$vs.loading();
            this.paramDetail.lines = detail
            this.$http.post('/api/v1/write-off/update', this.paramDetail)
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        this.$router.push("/finance/write-off");
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
            // console.log(this.paramDetail, "detail", detail)
        },
        handleShowAttachment(file) {
            return this.downloadFileAwsS3(file.PathFile)
            // let objectURL
            // console.log(file)
            // objectURL = URL.createObjectURL(file.File);

            // // link.download = file.name; // this name is used when the user downloads the file
            // var href = objectURL;
            // window.open(href, '_blank').focus();
        },
        handleDeleteAttachment(id) {
            // const deleteAttachment =this.fileAttachment[index]
            // this.fileAttachment = this.fileAttachment.filter((r, i) => {
            //     return i != index
            // })
            this.$vs.loading();
            var form = new FormData();
            form.append("list_id", id);
            form.append("id", this.$route.params.id);
            this.$http
                .post("/api/v1/write-off/delete-file", form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        // console.log(this.fileAttachment)
                        // console.log(id)
                        this.fileAttachment = this.fileAttachment.filter(v => {
                            return v.ListID != id
                        })
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        reloadData(params) {
            // console.log(Object.keys(this.paramDetail).length, 'aaaaaa')
            if (Object.keys(this.paramDetail).length === 0) {
                this.responseData.length = 0;
                this.responseData.recordsTotal = 0;
                return
            }
            this.paramDetail.page = params.page;
            this.paramDetail.length = params.length;
            this.paramDetail.search = params.search;
            this.$vs.loading();
            this.$http.get('/api/v1/write-off/document', {
                params: this.paramDetail,
            })
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                    } else if (r.code == 200) {
                        this.$vs.loading.close();
                        this.data = r.data.records;
                        this.responseData = r.data;
                        this.responseData.length = r.data.length;
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleAttachment() {

            if (this.$refs.file.files.length > 0) {
                this.lengthFile = this.$refs.file.files.length
                this.file = this.$refs.file.files;
                // for(let i =0; i < this.$refs.file.files.length; i++) {

                this.uploadData(0);
                // }

            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        uploadData(array) {
            // console.log(this.lengthFile, this.file, array)
            if (this.lengthFile <= array) {
                // this.$emit("closeDetail", true);
                // this.$vs.loading.close();
                document.querySelector("#fileInput").value = "";
                return
            }
            this.$vs.loading();
            this.$http
                .post("/api/v1/write-off/upload-file", this.paramUpload(array), {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {

                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.fileAttachment = []
                        resp.data.attachment.map(v => {
                            this.fileAttachment.push({
                                ListID: v.ListID,
                                NameFile: v.NameFile,
                                PathFile: v.PathFile,
                            });
                        })
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });

                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.uploadData(++array)
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                    this.uploadData(++array)
                });
        },
        paramUpload(array) {
            var form = new FormData();
            form.append("id", this.$route.params.id);
            form.append("file", this.file[array]);
            return form;
        },
        async getDocument() {
            if (this.create.operatingUnit == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operating Unit is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.transactionType == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Transaction  type is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.operation == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operation  type is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.aging <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Aging must be greater then 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.value <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Value must be greater then 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.distChannel == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Distribution Channel is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            let param = {
                salesman: this.create.salesman == null ? 0 : this.create.salesman.ID,
                type_doc: this.create.transactionType.value,
                customer: this.create.customer == null ? 0 : this.create.customer.ID,
                aging: this.create.aging,
                value: this.create.value.replace(/,/g, ''),
                length: 10,
                page: 1,
                operation: this.create.operation.value,
                territory_id: this.create.operatingUnit.territory_id,
                customer_category_id: this.create.distChannel.ID,
            };
            if (Object.keys(this.paramDetail).length !== 0) {
                if (this.paramDetail.type_doc != param.type_doc) {
                    this.checked = []
                    this.checkedID = []
                }
            }
            this.$vs.loading();
            // Get writeOff Provision & Expense setting
            let distChannelID = this.create.distChannel.ID
            
            await this.getSettingWriteOff(distChannelID);
            // console.log(this.settingWriteOff);
            // console.log(this.settingWriteOff.length);

            if (this.settingWriteOff.length > 0) {
                // console.log("Masuk get Document")
                // getDocument
                this.$http.get('/api/v1/write-off/document', {
                    params: param,
                }).then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                    } else if (r.code == 200) {
                        this.$vs.loading.close();
                        this.paramDetail = param

                        this.data = r.data.records;
                        this.responseData = r.data;
                        this.responseData.length = r.data.length;

                    } else {
                        this.$vs.loading.close();

                    }
                }).catch((e) => {
                    console.log(e)
                })
            }else{
                this.data = [];
                this.responseData = {};
                this.responseData.length = 0;
            }
        },
        getOptionSalesman(query) {
            if (this.create.operatingUnit == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operating Unit is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (query.length <= 2) {
                return
            }
            let param = {
                territory_id: this.create.operatingUnit.territory_id,
                search: query,
            };
            this.loading.salesman = true
            this.counterLoading.salesman++
            this.$http.get('/api/v1/write-off/salesman', {
                params: param,
            }).then((r) => {
                this.option.salesman = r.data.salesman
                this.counterLoading.salesman--
                if (this.counterLoading.salesman == 0) {
                    this.loading.salesman = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },
        getOptionCustomer(query, selected = 0) {
            if (this.create.operatingUnit == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operating Unit is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.distChannel == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Distribution Channel is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (query.length <= 2) {
                return
            }
            // console.log(this.create.operatingUnit)
            let param = {
                territory_id: this.create.operatingUnit.territory_id,
                customer_category_id: this.create.distChannel.ID,
                search: query,
            };

            this.loading.customer = true
            this.counterLoading.customer++
            this.$http.get('/api/v1/write-off/customer', {
                params: param,
            }).then((r) => {
                this.option.customer = r.data.customer

                this.option.customer.map(v => {
                    console.log(v.PersonID == selected, v, selected)
                    if (v.ID == selected) {
                        this.create.customer = v
                    }
                })
                this.counterLoading.customer--
                if (this.counterLoading.customer == 0) {
                    this.loading.customer = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },
        getOptionDistChannel(query) {            
            let param = {
                search: query,
            };
            // console.log(param)

            this.loading.distChannel = true
            this.counterLoading.distChannel++
            this.$http.get('/api/v1/write-off/distribution-channel', {
                params: param,
            }).then((r) => {
                // console.log(r);
                this.option.distChannel = r.data.distributionChannel

                this.counterLoading.distChannel--
                if (this.counterLoading.distChannel == 0) {
                    this.loading.distChannel = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },
        dataTable(params) {
            console.log(this.operatingUnit, 'dttb')
            return new Promise((resolve, reject) => {
                this.$http.get('/api/v1/bank-statement/line/data-table/' + this.bankStatementID, {
                    params: {
                        status: 0,
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        order: params.sort,
                        sort: params.order,
                        // territory_id: this.operatingUnit ? this.operatingUnit.OperatingUnitTerritory.TerritoryID : 0,
                        // operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
                    }
                }).then((r) => {
                    resolve(r)
                }).catch((e) => {
                    reject(e)
                })
            })
        },

        handleClose() {
            this.reloadData(this.params)
            this.selectedData.class = ""
            this.detail = false
            this.selectedData = {
                ID: 0,
            };
        },
        formatPrice(angka, prefix = "") {
            return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        countProvisionExpense (data) {
            let value_remain, provision_, expense_, percentProv, percentExpense, set_;
            if (this.paramDetail.type_doc == '01') {
                value_remain = data.Remaining;
            }else if (this.paramDetail.type_doc == '02') {                
                value_remain = data.DepositValueRaw;                
            } else if (this.paramDetail.type_doc == '03') {
                value_remain = data.TotalValue - data.UsedValue;
            }            
            // let DistChannel = "GT";
            // console.log("684 -------- ",data);
            // console.log("data.Aging -------",data.Aging)

            // filter
            set_ = this.settingWriteOff.filter(s => {
                return (data.Aging >= s.From && data.Aging <= s.To)
            })[0];

            // console.log(set_)

            if(set_ == null || set_ == undefined){
                this.$vs.notify({
                    color: "warning",
                    title: "Missing Setting",
                    text: "Please setup write-off parameter",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return;
            }

            // console.log("ini provision", provision)
            // data.Provision = percentProv * value_remain 
            data.Provision = parseFloat(set_.Provision / 100) * value_remain 
            provision_ = data.Provision.toFixed(2)

            // data.Expense = percentExpense * value_remain 
            data.Expense = parseFloat(set_.Expense / 100) * value_remain 
            expense_ = data.Expense.toFixed(2)

            return {provision: provision_, expense: expense_}
        },
        async getSettingWriteOff(distChannelID) {
            await this.$http.get("/api/v1/setting/write-off-parameters/distchannel/"+distChannelID).then((resp) => {
                if (resp.data.length > 0 && resp.data != null) {
                    this.settingWriteOff = resp.data;  
                    // this.$vs.loading.close();
                } else {
                    this.settingWriteOff = [];                    
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "warning",
                        title: "Missing Setting",
                        text: "Please setup write-off parameter",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            });
        },


    },
};
</script>
<style scoped>
.nonfixed {
    position: inherit;
    padding-left: 20px;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transform-origin-y: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-right: 1px solid #7367f0;
    /* min-height: 800px; */
    /* height: 100%; */
    -webkit-mask-position-y: fixed;
    /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
    background: beige;
}

.highlight>td {
    background-color: #d0cdf0;
}

.colored {
    border: 1px solid #7367f0;
    position: fixed;
    left: 4%;
    top: 40%;
    max-width: 45%;
    z-index: 999999999999;
    background: antiquewhite;
    background-color: antiquewhite;
}

.vs-dialog {
    max-width: 1000px;
}
</style>
